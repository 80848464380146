import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Tooltip } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  RSelect,
  CodeBlock,
} from "../components/Component";
import { fetchConnectAPI, getCookie } from "../utils/Utils";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import ConnectShimmer from "./connect/ConnectShimmer";
import { toast } from "react-toastify";
import useUserList from "../components/hooks/UseUserList";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Pusher from "pusher-js";
import { socket } from "../layout/ConnectLayout";

const Connect = () => {
  const navigate = useNavigate();

  const { room_name } = useParams();
  const [verifyMeeting, setVerifyMeeting] = useState(true);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [settingPopup, setSettingPopup] = useState(false);
  const [audioOn, setAudioOn] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [visibility, setVisibility] = useState("0");
  const [inviteUserList, setInviteUserList] = useState([]);
  const [inviteEmails, setInviteEmails] = useState([]);
  const inviteToggle = () => {
    setInvitePopup(!invitePopup);
  };
  const settingToggle = () => {
    setSettingPopup(!setSettingPopup);
  };
  let localMeetingData = localStorage.getItem(room_name);
  useEffect(() => {
    verifyMeetingAccess();
    // socket.on(`notification_left_${room_name}`, (data) => {
    //   toast.info("Organizer left meeting");
    //   setVerifyMeeting(true);
    //   setTimeout(() => {
    //     navigate("/");
    //   }, 2000);
    // });
    return () => {
      socket.off(`notification_left_${room_name}`);
    };
  }, []);
  const verifyMeetingAccess = async () => {
    let verifyMeetingResponse = await fetchConnectAPI(null, `verify-meeting-code/${room_name}`);
    if (verifyMeetingResponse.status === 200) {
      if (verifyMeetingResponse?.response?.status) {
        setVerifyMeeting(false);
        let local_room_name =
          localMeetingData && JSON.parse(localMeetingData).room_name ? JSON.parse(localMeetingData).room_name : null;
        let verifyMeetingResponseData = verifyMeetingResponse?.response?.data;
        let localSettingData = {
          mic: verifyMeetingResponseData.setting.mic,
          camera: verifyMeetingResponseData.setting.camera,
        };
        setAudioOn(() => (verifyMeetingResponseData.setting.mic == "2" ? false : true));
        setVideoOn(() => (verifyMeetingResponseData.setting.camera == "2" ? false : true));
        if (verifyMeetingResponseData.is_admin) {
          setIsAdmin(true);
          let tmpEmails = verifyMeetingResponseData.meeting.invited_emails || "";
          let tmpUsers = verifyMeetingResponseData.meeting.invited_usernames || "";
          tmpEmails = tmpEmails.split(",").filter((i) => i);
          tmpUsers = tmpUsers.split(",").filter((i) => i);
          setInviteEmails(tmpEmails);
          setInviteUserList(tmpUsers);
          setVisibility(verifyMeetingResponseData.meeting.visibility);
        }
        localStorage.setItem("connect_setting", JSON.stringify(localSettingData));
      } else {
        toast.error(verifyMeetingResponse?.response?.message);
        setTimeout(() => {
          navigate(`/`);
          setVerifyMeeting(false);
        }, 2000);
      }
    } else {
      toast.info("Please try again");
      setVerifyMeeting(false);
    }
  };
  const [filterUserList, setFilterUserList] = useState([] || null);
  const [userInputValue, setUserInputValue] = useState("");
  const UserList = useUserList();
  useEffect(() => {
    const List = UserList.filter((option) => option.label.toLowerCase().includes(userInputValue.toLowerCase()));
    setFilterUserList(List.slice(0, 5));
  }, [setFilterUserList, UserList, userInputValue]);
  const handleUserSearch = async (e) => {
    const inputValue = e.map((option) => option.value);
    setInviteUserList(inputValue);
  };
  const handleChangeTags = (tags) => {
    const regexEmail =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    if (allTagsValid) {
      setInviteEmails(tags);
    } else {
      toast.error("Enter valid email addresses");
    }
  };
  const inviteUsersClick = async () => {
    setInviteLoading(true);

    const data = {
      visibility: visibility,
      invited_usernames: inviteUserList,
      invited_emails: inviteEmails,
    };
    let inviteRes = await fetchConnectAPI(data, `invite-to-meeting/${room_name}`, true, "json");
    if (inviteRes.status == 200) {
      if (inviteRes.response.status) {
        toast.success(inviteRes.response.message);
        setInvitePopup(false);
      } else {
        toast.error(inviteRes.response.message);
      }
    } else {
      toast.error("Please try again");
    }
    setInviteLoading(false);
  };
  const settingClick = async () => {
    setSettingLoading(true);
    const data = {
      camera: videoOn ? "1" : "2",
      mic: audioOn ? "1" : "2",
      action: "update",
    };
    let settingRes = await fetchConnectAPI(data, `update-settings`, true, "json");
    if (settingRes.status == 200) {
      if (settingRes.response.status) {
        toast.success(settingRes.response.message);
        setSettingPopup(false);
      } else {
        toast.error(settingRes.response.message);
      }
    } else {
      toast.error("Please try again");
    }
    setSettingLoading(false);
  };
  const [publicInfoTooltipOpen, setPublicInfoTooltipOpen] = useState(false);

  const publicInfoTooltipToggle = () => {
    setPublicInfoTooltipOpen(!publicInfoTooltipOpen);
  };
  const [inviteTooltipOpen, setInviteTooltipOpen] = useState(false);

  const inviteTooltipToggle = () => {
    setInviteTooltipOpen(!inviteTooltipOpen);
  };
  const visibilityClicked = async (visibilityValue) => {
    // toast.info(`Updating visibility`);
    const fd = new FormData();
    fd.append("visibility", visibilityValue);
    let setMeetingVisibilityRes = await fetchConnectAPI(fd, `set-meeting-visibility/${room_name}`);
    if (setMeetingVisibilityRes.status == 200) {
      if (setMeetingVisibilityRes.response.status) {
        toast.success(setMeetingVisibilityRes.response.message);
      } else {
        toast.error(setMeetingVisibilityRes.response.message);
      }
    } else {
      console.log("error in setting visibility");
    }
  };
  if (verifyMeeting) return <ConnectShimmer />;
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content className="p-0 sps">
        <Block>
          <Row className="position-relative">
            <ConnectJitsi
              lg={12}
              room_name={room_name}
              setVerifyMeeting={setVerifyMeeting}
              isAdmin={isAdmin}
              localMeetingData={localMeetingData}
              videoOn={videoOn}
              audioOn={audioOn}
              className="m-0"
            />
            <div className="position-absolute inviteButtons">
              {isAdmin && (
                <>
                  <Button
                    color="primary"
                    className="ms-1 pointer"
                    size="sm"
                    onClick={() => setInvitePopup(true)}
                    id="invite_button"
                  >
                    <Icon name={"curve-up-right"} className={` `}></Icon>
                  </Button>
                  <Tooltip
                    placement={`top`}
                    isOpen={inviteTooltipOpen}
                    target="invite_button"
                    toggle={inviteTooltipToggle}
                  >
                    Invite
                  </Tooltip>
                </>
              )}
              <Button color="primary" className="ms-1 d-none" onClick={() => setSettingPopup(true)}>
                Setting
              </Button>
            </div>
          </Row>
        </Block>
      </Content>
      <Modal isOpen={invitePopup} toggle={inviteToggle}>
        <ModalHeader
          toggle={inviteToggle}
          close={
            <button className="close text-primary" onClick={inviteToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Connect Invitation
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} lg={12} className={`mb-2`}>
              <span className="preview-title overline-title">Visibility</span>
              <div className="custom-control custom-control-sm custom-radio me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioVisibility"
                  checked={visibility === "0"}
                  id="rdoNone"
                  onClick={() => {
                    visibilityClicked(0);
                    setVisibility("0");
                  }}
                />
                <label className="custom-control-label" htmlFor="rdoNone">
                  None
                </label>
              </div>
              <div className="custom-control custom-control-sm custom-radio me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioVisibility"
                  checked={visibility === "1"}
                  id="rdoFollowers"
                  onClick={() => {
                    visibilityClicked(1);
                    setVisibility("1");
                  }}
                />
                <label className="custom-control-label" htmlFor="rdoFollowers">
                  Followers
                </label>
              </div>
              <div className="custom-control custom-control-sm custom-radio me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioVisibility"
                  id="rdoConnection"
                  onClick={() => {
                    visibilityClicked(2);
                    setVisibility("2");
                  }}
                  checked={visibility === "2"}
                />
                <label className="custom-control-label" htmlFor="rdoConnection">
                  Connection
                </label>
              </div>
              <div className="custom-control custom-control-sm custom-radio me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioVisibility"
                  id="rdoPublic"
                  onClick={() => {
                    visibilityClicked(3);
                    setVisibility("3");
                  }}
                  checked={visibility === "3"}
                />
                <label className="custom-control-label" htmlFor="rdoPublic">
                  Public using share link
                  <Icon className="px-1 mt-1" name={`info`} id={"public_info"} />
                  <Tooltip
                    placement={`right`}
                    isOpen={publicInfoTooltipOpen}
                    target="public_info"
                    toggle={publicInfoTooltipToggle}
                  >
                    Click invite to set public
                  </Tooltip>
                </label>
              </div>
            </Col>
            {visibility == "3" && (
              <Col lg={12} sm={12} className="my-1">
                <CodeBlock language="jsx" title="Share Link">
                  {`${window.location.origin}/public/${room_name}`}
                </CodeBlock>
              </Col>
            )}
            <Col sm={12} lg={12} className={`mb-2`}>
              <div className="form-group">
                <label className="form-label">Invite Users</label>
                <div className="form-control-wrap ">
                  <RSelect
                    placeholder="Search User..."
                    options={filterUserList}
                    isMulti
                    defaultValue={
                      Array.isArray(inviteUserList)
                        ? inviteUserList.map((username) => ({ value: username, label: username }))
                        : []
                    }
                    onChange={handleUserSearch}
                    onInputChange={(value) => {
                      setUserInputValue(value);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} lg={12} className={`mb-2`}>
              <div className="form-group">
                <label className="form-label">Invite Emails</label>
                <div className="form-control-wrap ">
                  <TagsInput
                    className="form-control p-0 w-100"
                    value={Array.isArray(inviteEmails) ? inviteEmails : [inviteEmails]}
                    onChange={handleChangeTags}
                    inputProps={{
                      className: "w-100 react-tagsinput-input tagsinput-color",
                      placeholder: "Enter E-mail",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-end`}>
              {inviteLoading ? (
                <>
                  <Button disabled color="primary">
                    <Spinner size="sm" />
                    <span> Inviting... </span>
                  </Button>
                </>
              ) : (
                <Button color="primary" onClick={inviteUsersClick}>
                  Invite
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light d-none">
          <span className="sub-text">Modal Footer Text</span>
        </ModalFooter>
      </Modal>
      <Modal isOpen={settingPopup} toggle={settingToggle}>
        <ModalHeader
          toggle={settingToggle}
          close={
            <button className="close text-primary" onClick={settingToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Connect Settings
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} lg={12} className={`mb-2  d-flex justify-content-start d-none`}>
              <div>
                <span className="preview-title overline-title mt-1 me-2">Mic</span>
              </div>
              <div className="custom-control custom-control-sm custom-radio  mt-1 me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioMic"
                  checked={audioOn}
                  id="rdoMicOn"
                  onClick={() => setAudioOn(true)}
                />
                <label className="custom-control-label" htmlFor="rdoMicOn">
                  On
                </label>
              </div>
              <div className="custom-control custom-control-sm custom-radio  mt-1 me-2">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioMic"
                  checked={!audioOn}
                  id="rdoMicOff"
                  onClick={() => setAudioOn(false)}
                />
                <label className="custom-control-label" htmlFor="rdoMicOff">
                  Off
                </label>
              </div>
            </Col>
            <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-start d-none`}>
              <div>
                <span className="preview-title overline-title mt-1 me-2">Camera</span>
              </div>
              <div className="custom-control custom-control-sm custom-radio me-2 mt-1">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioCamera"
                  checked={videoOn}
                  id="rdoCameraOn"
                  onClick={() => setVideoOn(true)}
                />
                <label className="custom-control-label" htmlFor="rdoCameraOn">
                  On
                </label>
              </div>
              <div className="custom-control custom-control-sm custom-radio me-2 mt-1">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="radioCamera"
                  checked={!videoOn}
                  id="rdoCameraOff"
                  onClick={() => setVideoOn(false)}
                />
                <label className="custom-control-label" htmlFor="rdoCameraOff">
                  Off
                </label>
              </div>
            </Col>
            <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-start`}>
              <div>
                <span className="preview-title overline-title mt-1 me-2" style={{ width: "100px" }}>
                  Camera
                </span>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked={videoOn}
                  id="switchCamera"
                  // checked=
                  onChange={(e) => {
                    setVideoOn(e.target.checked);
                  }}
                />
                <label className="custom-control-label" htmlFor="switchCamera">
                  On
                </label>
              </div>
            </Col>
            <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-start`}>
              <div>
                <span className="preview-title overline-title mt-1 me-2" style={{ width: "100px" }}>
                  Mic
                </span>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked={audioOn}
                  id="switchMic"
                  onChange={(e) => {
                    setAudioOn(e.target.checked);
                  }}
                />
                <label className="custom-control-label" htmlFor="switchMic">
                  On
                </label>
              </div>
            </Col>

            <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-end`}>
              {settingLoading ? (
                <>
                  <Button disabled color="primary">
                    <Spinner size="sm" />
                    <span> Updating... </span>
                  </Button>
                </>
              ) : (
                <Button color="primary" onClick={settingClick}>
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light d-none">
          <span className="sub-text">Modal Footer Text</span>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
const ConnectJitsi = ({
  room_name,
  setVerifyMeeting,
  lg = 12,
  isAdmin = false,
  localMeetingData,
  videoOn,
  audioOn,
}) => {
  const navigate = useNavigate();
  const jitsiContainerStyle = {
    width: "100%",
    height: "100%",
    color: "#000",
    backgroundColor: "#000",
  };
  const onApiReady = (externalApi) => {
    externalApi.addEventListener("videoConferenceLeft", () => {
      if (localMeetingData) {
        localStorage.removeItem(room_name);
      }
      setVerifyMeeting(true);
      // if (isAdmin) closeMeeting();
      toast.info("You have left meeting");
      setTimeout(() => {
        navigate(`/`);
      }, 1000);
    });
    externalApi.addEventListener("videoConferenceJoined", (data) => {
      if (isAdmin) {
        const fd = new FormData();
        fd.append("admin_id", data.id);
        fd.append("is_admin_joined", "1");
        const adminJoinedRes = fetchConnectAPI(fd, `admin-join/${room_name}`);
      }
    });
    externalApi.addEventListener("participantLeft", (user) => {
      const fd = new FormData();
      fd.append("admin_id", user.id);
      fd.append("is_admin_joined", "0");
      // const adminLeftRes = fetchConnectAPI(fd, `public/leave-meeting/${room_name}`, false);
    });
    externalApi.addEventListener("participantJoined", (participant) => {});
  };
  const closeMeeting = async () => {
    let verifyMeetingResponse = await fetchConnectAPI(null, `close-meeting/${room_name}`);
    if (verifyMeetingResponse.status === 200) {
      if (verifyMeetingResponse?.response?.status) {
        setVerifyMeeting(true);
        setTimeout(function () {
          navigate("/");
        }, 2000);
      } else {
        toast.error(verifyMeetingResponse?.response?.message);
      }
    } else {
      toast.error("Please try again");
    }
  };
  const configOverwrite = {
    prejoinPageEnabled: false,
    remoteVideoMenu: { disableKick: false },
    disableRemoteMute: false,
    disableProfile: true,
    disableInviteFunctions: true,
    enableWelcomePage: false,
    enableClosePage: true,
    deeplinking: { disabled: true },
  };
  const adminToolbar = isAdmin ? ["mute-everyone", "mute-video-everyone"] : [];
  const interfaceConfigOverwrite = {
    CLOSE_PAGE_GUEST_HINT: true,
    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    HIDE_INVITE_MORE_HEADER: true,
    SETTINGS_SECTIONS: isAdmin ? ["devices", "language", "moderator", "profile"] : ["devices", "language", "profile"],
    SHARING_FEATURES: [],
    TOOLBAR_BUTTONS: [
      "microphone",
      "camera",
      "closedcaptions",
      "desktop",
      // "fullscreen",
      "fodeviceselection",
      "profile",
      "chat",
      "etherpad",
      "sharedvideo",
      "settings",
      "raisehand",
      "videoquality",
      "filmstrip",
      "stats",
      "shortcuts",
      "tileview",
      "select-background",
      "download",
      "security",
      ...adminToolbar,
    ],
  };
  const userInfo = {
    email: getCookie("email", "johndoe@mail.com"),
    displayName: getCookie("username", "john doe"),
  };

  const config = {
    startWithVideoMuted: !videoOn,
    startWithAudioMuted: !audioOn,
    prejoinConfig: {
      enabled: false,
    },
  };
  return (
    <>
      <Col sm={12} lg={lg} className="text-center">
        <div id="jitsi-container" style={jitsiContainerStyle}>
          <JitsiMeeting
            domain={`${process.env.REACT_APP_JITSI_DOMAIN}`}
            roomName={room_name}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = `calc(100vh - 120px)`;
            }}
            onApiReady={onApiReady}
            configOverwrite={config}
            interfaceConfigOverwrite={{
              HIDE_INVITE_MORE_HEADER: true,
            }}
            userInfo={userInfo}
          />
        </div>
      </Col>
    </>
  );
};
export default Connect;
