import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Spinner, Tooltip } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  UserAvatar,
} from "../components/Component";
import { useNavigate } from "react-router-dom";
import {
  fetchConnectAPI,
  formatDate,
  formatTime,
  getCombinedDate,
  getCurrentDateTimeInFormat,
  getInitials,
} from "../utils/Utils";
import { toast } from "react-toastify";
import ConnectShimmer from "./connect/ConnectShimmer";
import InviteModal from "./InviteModal";
import SettingModal from "./SettingModal";
import DatePicker from "react-datepicker";
import CopyToClipboard from "react-copy-to-clipboard";
const ConnectDashboard = () => {
  const [copyState, setCopyState] = useState(false);
  const [copyText, setCopyText] = useState("");
  const onCopyClick = (text) => {
    setCopyState(true);
    setCopyText(text);
    setTimeout(() => {
      setCopyText("");
      setCopyState(false);
    }, 2000);
  };
  const [audioOn, setAudioOn] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const [toBeDeleteMeetingId, setToBeDeleteMeetingId] = useState(null);
  const [link, setLink] = useState("");
  const [invitePopup, setInvitePopup] = useState(false);

  const [joinTooltipOpen, setJoinTooltipOpen] = useState({});

  const toggleJoinTooltip = (index) => {
    setJoinTooltipOpen({
      ...joinTooltipOpen,
      [index]: !joinTooltipOpen[index],
    });
  };

  const [inviteTooltipOpen, setInviteTooltipOpen] = useState({});

  const toggleInviteTooltip = (index) => {
    setInviteTooltipOpen({
      ...inviteTooltipOpen,
      [index]: !inviteTooltipOpen[index],
    });
  };
  const [editTooltipOpen, setEditTooltipOpen] = useState({});

  const toggleEditTooltip = (index) => {
    setEditTooltipOpen({
      ...editTooltipOpen,
      [index]: !editTooltipOpen[index],
    });
  };

  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState({});

  const toggleDeleteTooltip = (index) => {
    setDeleteTooltipOpen({
      ...deleteTooltipOpen,
      [index]: !deleteTooltipOpen[index],
    });
  };

  const navigate = useNavigate();
  const [startMeeting, setStartMeeting] = useState(false);
  const [meetingList, setMeetingList] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [invitedMeetingId, setInvitedMeetingId] = useState(null);
  const [settingPopup, setSettingPopup] = useState(false);
  const [schedulingListLoading, setSchedulingListLoading] = useState(false);
  const [schedulingLoading, setSchedulingLoading] = useState(false);
  const [editMeetingLoading, setEditMeetingLoading] = useState(false);
  const [inviteMeetingLoading, setInviteMeetingLoading] = useState(false);
  const [deleteMeetingLoading, setDeleteMeetingLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    room_name: null,
    id: null,
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  useEffect(() => {
    getScheduledMeetingList();
  }, []);
  const scheduleMeetingClick = async () => {
    if (!formData.title) {
      toast.error(`Enter Title`);
      return false;
    }
    if (formData.title.length > 255) {
      toast.error(`Enter Title with maximum 255 characters`);
      return false;
    }
    if (!formData.startDate) {
      toast.error(`Enter start date`);
      return false;
    }
    if (!formData.endDate) {
      toast.error(`Enter end date`);
      return false;
    }
    if (!formData.startTime) {
      toast.error(`Enter start time`);
      return false;
    }
    if (!formData.endTime) {
      toast.error(`Enter end time`);
      return false;
    }
    setSchedulingLoading(true);
    let sd = formData.startDate;
    let ed = formData.endDate;

    let sd_t = formData.startTime;
    let ed_t = formData.endTime;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);
    let fd = new FormData();

    if (formData.id) fd.append("id", formData.id);

    fd.append("title", formData.title);
    fd.append("meeting_start_time", getCurrentDateTimeInFormat(startDateTime));
    fd.append("meeting_end_time", getCurrentDateTimeInFormat(endDateTime));
    const scheduleMeetingRes = await fetchConnectAPI(fd, "schedule-meeting");
    if (scheduleMeetingRes.status == 200) {
      if (scheduleMeetingRes.response.status) {
        if (!formData.id) {
          setMeetingList((prev) => {
            return [scheduleMeetingRes?.response?.data?.updated_meetings[0], ...prev];
          });
        } else {
          let tempMeetingListAfterUpdate = meetingList.map((tempMeetingItem) => {
            if (tempMeetingItem.id == formData.id) {
              return scheduleMeetingRes?.response?.data?.updated_meetings[0];
            } else {
              return tempMeetingItem;
            }
          });
          setMeetingList(tempMeetingListAfterUpdate);
        }
        toast.success(scheduleMeetingRes.response.message);
        scheduleToggle();
        setFormData({
          title: "",
          room_name: null,
          id: null,
          startDate: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          endDate: new Date(),
        });
      } else {
        toast.error(scheduleMeetingRes.response.message);
      }
    }

    setSchedulingLoading(false);
  };
  const goLiveClick = async () => {
    setStartMeeting(true);
    let fd = new FormData();
    let createMeetingResponse = await fetchConnectAPI(fd, "create-meeting-code");
    if (createMeetingResponse.status === 200) {
      if (createMeetingResponse?.response?.status) {
        let createMeetingResponseData = createMeetingResponse?.response?.data;
        let localMeetingData = {
          room_name: createMeetingResponseData.room_name,
          is_admin: createMeetingResponseData.is_admin,
        };
        let localSettingData = {
          mic: createMeetingResponseData.setting.mic,
          camera: createMeetingResponseData.setting.camera,
        };
        localStorage.setItem("connect_setting", JSON.stringify(localSettingData));
        localStorage.setItem(createMeetingResponseData.room_name, JSON.stringify(localMeetingData));
        navigate(`/connect/${createMeetingResponseData.room_name}`);
      } else {
        toast.error(createMeetingResponse?.response?.message);
      }
    } else {
      toast.info("Please try again");
    }
    setStartMeeting(false);
  };
  const joinMeeting = () => {
    navigate(`/connect/${link}`);
  };
  const settingToggle = () => {
    setSettingPopup(!settingPopup);
  };
  const linkKeyUpHandle = (e) => {
    if (e.keyCode === 13 && link.length == 9) {
      navigate(`/connect/${link}`);
    }
  };
  const currentDateTime = new Date();
  const [isJoinOpen, setIsJoinOpen] = useState(false);

  const joinToggle = () => {
    setIsJoinOpen(!isJoinOpen);
  };
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);

  const scheduleToggle = () => {
    setIsSchedulerOpen(!isSchedulerOpen);
    if (!formData.id) {
      let currentStartTime = new Date();
      let currentEndTime = new Date();
      currentStartTime.setMinutes(currentStartTime.getMinutes() + 5);
      currentEndTime.setMinutes(currentEndTime.getMinutes() + 20);
      setFormData({ ...formData, startTime: currentStartTime, endTime: currentEndTime });
    }
  };
  const deleteMeeting = async (meetingItem) => {
    setDeleteMeetingLoading(true);
    let fd = new FormData();
    fd.append("id", meetingItem.id);
    let deleteMeetingRes = await fetchConnectAPI(fd, "delete-schedule-meeting");
    if (deleteMeetingRes.status == 200) {
      if (deleteMeetingRes.response.status) {
        toast.success(deleteMeetingRes.response.message);
        let tempMeetingListAfterDelete = meetingList.filter((tempMeetingItem) => tempMeetingItem.id != meetingItem.id);
        setMeetingList(tempMeetingListAfterDelete);
      } else {
        toast.error(deleteMeetingRes.response.message);
      }
    } else {
      console.error("error in deleteMeeting");
    }
    setDeleteMeetingLoading(false);
  };
  const getScheduledMeetingList = async () => {
    setSchedulingListLoading(true);
    let fd = new FormData();
    fd.append("duration", "current");
    fd.append("duration_type", "days");
    let scheduleMeetingListRes = await fetchConnectAPI(fd, "schedule-meeting-list");
    if (scheduleMeetingListRes.status == 200) {
      if (scheduleMeetingListRes.response.status) {
        setMeetingList(scheduleMeetingListRes?.response?.data?.meetings);
      }
      setAudioOn(() => (scheduleMeetingListRes?.response?.data?.settings?.mic == "2" ? false : true));
      setVideoOn(() => (scheduleMeetingListRes?.response?.data?.settings?.camera == "2" ? false : true));
    } else {
      console.error("error in getScheduledMeetingList");
    }
    setSchedulingListLoading(false);
  };
  const selectMeeting = (meeting) => {
    if (meeting?.host) setSelectedMeeting(meeting);
  };
  const inviteMeeting = (meetingItem) => {
    setSelectedMeeting(meetingItem);
    setInvitePopup(true);
    setInviteMeetingLoading(false);
  };
  const editMeeting = (meetingItem) => {
    setEditMeetingLoading(true);

    setTimeout(() => {
      setIsSchedulerOpen(true);
      setEditMeetingLoading(false);
    }, 1000);
  };
  if (startMeeting) return <ConnectShimmer />;
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <div className="container d-flex" style={{ height: "calc(100vh - 160px)" }}>
            <Row className="justify-content-center">
              <Col sm={12} lg={6} className="">
                <Row className="h-100 meeting-content">
                  <div className="col-6 text-center my-auto" onClick={goLiveClick}>
                    <div className="bg-primary mx-auto">
                      <Icon name="video" className="display-5" />
                    </div>
                    <h6>Go Live</h6>
                  </div>
                  <div className="col-6 text-center my-auto" onClick={joinToggle}>
                    <div className="bg-secondary mx-auto">
                      <Icon name="plus" className="display-5" />
                    </div>
                    <h6>Join</h6>
                  </div>
                  <div
                    className="col-6 text-center my-auto"
                    onClick={() => {
                      setFormData({
                        title: "",
                        room_name: null,
                        id: null,
                        startDate: new Date(),
                        startTime: new Date(),
                        endTime: new Date(),
                        endDate: new Date(),
                      });
                      scheduleToggle();
                    }}
                  >
                    <div className="bg-secondary mx-auto">
                      <Icon name="calendar" className="display-5" />
                    </div>
                    <h6>Schedule</h6>
                  </div>
                  <div className="col-6 text-center my-auto" onClick={() => settingToggle()}>
                    <div className="bg-secondary mx-auto">
                      <Icon name="setting" className="display-5" />
                    </div>
                    <h6>Setting</h6>
                  </div>
                </Row>
              </Col>
              <Col sm={12} lg={5} className="my-auto">
                <div>
                  <div className="card" style={{ height: "calc(100vh - 250px)" }}>
                    <div className="card-body">
                      <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ height: "25%" }}
                      >
                        <h5>{formatTime(currentDateTime)}</h5>
                        <h6 className="card-title text-center">{formatDate(currentDateTime)}</h6>
                      </div>
                      {schedulingListLoading ? (
                        Array(5)
                          .fill("")
                          .map((_, index) => {
                            return (
                              <div key={index} className="d-flex justify-content-evenly align-items-center">
                                <div
                                  className="shimmer shimmer-thumbnail border-rounded"
                                  style={{ height: "40px", width: "90%" }}
                                ></div>
                              </div>
                            );
                          })
                      ) : (
                        <>
                          {meetingList.length > 0 ? (
                            <div className="meeting-list" style={{ overflowX: "scroll", height: "70%" }}>
                              {meetingList.map((meetingItem, meetingIndex) => {
                                return (
                                  <div
                                    className={``}
                                    key={meetingItem?.room_name}
                                    style={{ borderBottom: "1px solid black" }}
                                  >
                                    <div className={`user-card p-1`}>
                                      <UserAvatar text={getInitials(meetingItem?.hostname)}></UserAvatar>
                                      <div className="user-info w-100">
                                        <div className="d-flex justify-content-between">
                                          <span className="lead-text">{meetingItem?.title || "UNTITLED"}</span>
                                          <div className="meeting-icons">
                                            <span>
                                              <Icon
                                                name={"plus"}
                                                id={`meeting-join-${meetingIndex}`}
                                                className={`ms-1 pointer p-1`}
                                                onClick={() => {
                                                  navigate("/connect/" + meetingItem?.room_name);
                                                }}
                                              ></Icon>
                                              <Tooltip
                                                placement="top"
                                                isOpen={joinTooltipOpen[meetingIndex]}
                                                target={`meeting-join-${meetingIndex}`}
                                                toggle={() => toggleJoinTooltip(meetingIndex)}
                                              >
                                                Join
                                              </Tooltip>
                                            </span>
                                            {meetingItem?.host && (
                                              <>
                                                {inviteMeetingLoading && invitedMeetingId == meetingItem.id && (
                                                  <div
                                                    role="status"
                                                    className="ms-1 p-1 spinner-border-sm spinner-border"
                                                  ></div>
                                                )}
                                                <span
                                                  className={`${
                                                    inviteMeetingLoading && invitedMeetingId == meetingItem.id
                                                      ? "d-none"
                                                      : ""
                                                  }`}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    isOpen={inviteTooltipOpen[meetingIndex]}
                                                    target={`meeting-invite-${meetingIndex}`}
                                                    toggle={() => toggleInviteTooltip(meetingIndex)}
                                                  >
                                                    Invite
                                                  </Tooltip>
                                                  <Icon
                                                    id={`meeting-invite-${meetingIndex}`}
                                                    name={"curve-up-right"}
                                                    className={`ms-1 pointer p-1`}
                                                    onClick={() => {
                                                      setInviteMeetingLoading(true);
                                                      setInvitedMeetingId(meetingItem.id);
                                                      setTimeout(() => {
                                                        inviteMeeting(meetingItem);
                                                      }, 2000);
                                                    }}
                                                  ></Icon>
                                                </span>
                                                {editMeetingLoading && formData.id == meetingItem.id && (
                                                  <div
                                                    role="status"
                                                    className="ms-1 p-1 spinner-border-sm spinner-border"
                                                  ></div>
                                                )}
                                                <span
                                                  className={`${
                                                    editMeetingLoading && formData.id == meetingItem.id ? "d-none" : ""
                                                  }`}
                                                >
                                                  <Icon
                                                    id={`edit-meeting-${meetingIndex}`}
                                                    name={"edit"}
                                                    className={`ms-1 pointer p-1`}
                                                    onClick={() => {
                                                      setFormData({
                                                        title: meetingItem.title,
                                                        room_name: meetingItem.room_name,
                                                        id: meetingItem.id,
                                                        startDate: new Date(meetingItem.meeting_start_time),
                                                        endDate: new Date(meetingItem.meeting_end_time),
                                                        startTime: new Date(meetingItem.meeting_start_time),
                                                        endTime: new Date(meetingItem.meeting_end_time),
                                                      });
                                                      editMeeting(meetingItem);
                                                    }}
                                                  />
                                                  <Tooltip
                                                    placement="top"
                                                    isOpen={editTooltipOpen[meetingIndex]}
                                                    target={`edit-meeting-${meetingIndex}`}
                                                    toggle={() => toggleEditTooltip(meetingIndex)}
                                                  >
                                                    Edit
                                                  </Tooltip>
                                                  <span>
                                                    {deleteMeetingLoading && toBeDeleteMeetingId == meetingItem.id && (
                                                      <div
                                                        role="status"
                                                        className="ms-1 p-1 spinner-border-sm spinner-border"
                                                      ></div>
                                                    )}
                                                    <span
                                                      className={`${
                                                        deleteMeetingLoading && toBeDeleteMeetingId == meetingItem.id
                                                          ? "d-none"
                                                          : ""
                                                      }`}
                                                    >
                                                      <Tooltip
                                                        placement="top"
                                                        isOpen={deleteTooltipOpen[meetingIndex]}
                                                        target={`meeting-delete-${meetingIndex}`}
                                                        toggle={() => toggleDeleteTooltip(meetingIndex)}
                                                      >
                                                        Delete
                                                      </Tooltip>
                                                      <Icon
                                                        id={`meeting-delete-${meetingIndex}`}
                                                        name={"trash"}
                                                        className={`ms-1 pointer p-1`}
                                                        onClick={() => {
                                                          setToBeDeleteMeetingId(meetingItem.id);
                                                          deleteMeeting(meetingItem);
                                                        }}
                                                      ></Icon>
                                                    </span>
                                                  </span>
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        <span className="sub-text">
                                          Code: {meetingItem?.room_name}
                                          <CopyToClipboard
                                            text={meetingItem?.room_name}
                                            onCopy={() => onCopyClick(meetingItem?.room_name)}
                                          >
                                            <Icon
                                              name="copy"
                                              className={`p-1 pointer ${
                                                copyState && copyText == meetingItem?.room_name ? "text-primary" : ""
                                              }`}
                                            />
                                          </CopyToClipboard>
                                        </span>
                                        <span className="sub-text">
                                          Time: {formatTime(new Date(meetingItem?.meeting_start_time))} - &nbsp;
                                          {formatTime(new Date(meetingItem?.meeting_end_time))}
                                        </span>
                                      </div>
                                      <div className="user-action">{/* <DropdownTrans /> */}</div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="card-text text-center">No Upcoming Meetings</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Block>
      </Content>
      <Modal isOpen={isJoinOpen} toggle={joinToggle}>
        <ModalHeader
          toggle={joinToggle}
          close={
            <button className="close" onClick={joinToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Join Meeting
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-evenly g-gs-2">
            <input
              className="form-control form-control-sm mx-2 w-50"
              placeholder="Enter a 9 character meeting code"
              value={link}
              onKeyUp={(e) => linkKeyUpHandle(e)}
              onChange={(e) => setLink(e.target.value)}
            />
            <Button color="secondary" disabled={link.length != 9} onClick={joinMeeting}>
              Join
            </Button>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light d-none">
          <span className="sub-text">Modal Footer Text</span>
        </ModalFooter>
      </Modal>
      <SettingModal
        settingPopup={settingPopup}
        settingToggle={settingToggle}
        setSettingPopup={setSettingPopup}
        audioOn={audioOn}
        videoOn={videoOn}
        setAudioOn={setAudioOn}
        setVideoOn={setVideoOn}
      />
      {selectedMeeting && selectedMeeting.host && (
        <InviteModal
          room_name={selectedMeeting.room_name}
          setInvitePopup={setInvitePopup}
          invitePopup={invitePopup}
          selectedMeeting={selectedMeeting}
          setMeetingList={setMeetingList}
          meetingList={meetingList}
          invitedMeetingId={invitedMeetingId}
          setInvitedMeetingId={setInvitedMeetingId}
          setSelectedMeeting={setSelectedMeeting}
        />
      )}
      <Modal isOpen={isSchedulerOpen} toggle={scheduleToggle}>
        <ModalHeader
          toggle={scheduleToggle}
          close={
            <button className="close" onClick={scheduleToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Schedular {formData.room_name && `Room Name ${formData.room_name}`}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col sm="12">
              <div className="form-group">
                <Label htmlFor="title" className="form-label">
                  Title
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="title"
                    placeholder="Title"
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    value={formData.title}
                  />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Start Date &amp; Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData.startDate}
                        onChange={(date) => setFormData({ ...formData, startDate: date })}
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap has-timepicker">
                      <DatePicker
                        selected={formData.startTime}
                        onChange={(date) => setFormData({ ...formData, startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">End Date &amp; Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData.endDate}
                        onChange={(date) => setFormData({ ...formData, endDate: date })}
                        className="form-control date-picker"
                        minDate={formData.startDate}
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap has-timepicker">
                      <DatePicker
                        selected={formData.endTime}
                        onChange={(date) => setFormData({ ...formData, endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col lg={12} sm={12} className="d-flex justify-content-end">
              {schedulingLoading ? (
                <>
                  <Button disabled color="primary">
                    <Spinner size="sm" />
                    <span>{formData.id ? `Updating...` : `Scheduling`} </span>
                  </Button>
                </>
              ) : (
                <Button color="primary" onClick={scheduleMeetingClick}>
                  {formData.id ? `Update` : `Schedule`}
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ConnectDashboard;
