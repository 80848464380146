import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { fetchConnectAPI } from "../utils/Utils";
import { Icon } from "../components/Component";

const SettingModal = ({
  settingToggle,
  settingPopup,
  setSettingPopup,
  audioOn,
  videoOn,
  setAudioOn,
  setVideoOn,
  ...props
}) => {
  const [settingLoading, setSettingLoading] = useState(false);
  const settingClick = async () => {
    setSettingLoading(true);
    const data = {
      camera: videoOn ? "1" : "2",
      mic: audioOn ? "1" : "2",
      action: "update",
    };
    let settingRes = await fetchConnectAPI(data, `update-settings`, true, "json");
    if (settingRes.status == 200) {
      if (settingRes.response.status) {
        toast.success(settingRes.response.message);
        setSettingPopup(false);
      } else {
        toast.error(settingRes.response.message);
      }
    } else {
      toast.error("Please try again");
    }
    setSettingLoading(false);
  };
  return (
    <Modal isOpen={settingPopup} toggle={settingToggle}>
      <ModalHeader
        toggle={settingToggle}
        close={
          <button className="close text-primary" onClick={settingToggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Connect Settings
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-start`}>
            <div>
              <span className="preview-title overline-title mt-1 me-2" style={{ width: "100px" }}>
                Camera
              </span>
            </div>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input text-primary"
                defaultChecked={videoOn}
                id="switchCamera"
                // checked=
                onChange={(e) => {
                  setVideoOn(e.target.checked);
                }}
              />
              <label className="custom-control-label" htmlFor="switchCamera">
                On
              </label>
            </div>
          </Col>
          <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-start`}>
            <div>
              <span className="preview-title overline-title mt-1 me-2" style={{ width: "100px" }}>
                Mic
              </span>
            </div>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={audioOn}
                id="switchMic"
                onChange={(e) => {
                  setAudioOn(e.target.checked);
                }}
              />
              <label className="custom-control-label" htmlFor="switchMic">
                On
              </label>
            </div>
          </Col>

          <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-end`}>
            {settingLoading ? (
              <>
                <Button disabled color="primary">
                  <Spinner size="sm" />
                  <span> Updating... </span>
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={settingClick}>
                Save
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SettingModal;
