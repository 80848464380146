import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Spinner } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../components/Component";
import { useNavigate } from "react-router-dom";
import { fetchConnectAPI, getCombinedDate, getCurrentDateTimeInFormat } from "../utils/Utils";
import { toast } from "react-toastify";
import ConnectShimmer from "./connect/ConnectShimmer";
import InviteModal from "./InviteModal";
import SettingModal from "./SettingModal";
import DatePicker from "react-datepicker";
const ConnectDashboard2 = () => {
  const [copyState, setCopyState] = useState(false);
  const [copyText, setCopyText] = useState("");
  const onCopyClick = (text) => {
    setCopyState(true);
    setCopyText(text);
    setTimeout(() => {
      setCopyText("");
      setCopyState(false);
    }, 2000);
  };
  const [audioOn, setAudioOn] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const [toBeDeleteMeetingId, setToBeDeleteMeetingId] = useState(null);
  const [link, setLink] = useState("");
  const [invitePopup, setInvitePopup] = useState(false);

  const [joinTooltipOpen, setJoinTooltipOpen] = useState({});

  const toggleJoinTooltip = (index) => {
    setJoinTooltipOpen({
      ...joinTooltipOpen,
      [index]: !joinTooltipOpen[index],
    });
  };

  const [inviteTooltipOpen, setInviteTooltipOpen] = useState({});

  const toggleInviteTooltip = (index) => {
    setInviteTooltipOpen({
      ...inviteTooltipOpen,
      [index]: !inviteTooltipOpen[index],
    });
  };
  const [editTooltipOpen, setEditTooltipOpen] = useState({});

  const toggleEditTooltip = (index) => {
    setEditTooltipOpen({
      ...editTooltipOpen,
      [index]: !editTooltipOpen[index],
    });
  };

  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState({});

  const toggleDeleteTooltip = (index) => {
    setDeleteTooltipOpen({
      ...deleteTooltipOpen,
      [index]: !deleteTooltipOpen[index],
    });
  };

  const navigate = useNavigate();
  const [startMeeting, setStartMeeting] = useState(false);
  const [meetingList, setMeetingList] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [invitedMeetingId, setInvitedMeetingId] = useState(null);
  const [settingPopup, setSettingPopup] = useState(false);
  const [schedulingListLoading, setSchedulingListLoading] = useState(false);
  const [schedulingLoading, setSchedulingLoading] = useState(false);
  const [editMeetingLoading, setEditMeetingLoading] = useState(false);
  const [inviteMeetingLoading, setInviteMeetingLoading] = useState(false);
  const [deleteMeetingLoading, setDeleteMeetingLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    room_name: null,
    id: null,
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  useEffect(() => {
    getScheduledMeetingList();
  }, []);
  const scheduleMeetingClick = async () => {
    if (!formData.title) {
      toast.error(`Enter Title`);
      return false;
    }
    if (formData.title.length > 255) {
      toast.error(`Enter Title with maximum 255 characters`);
      return false;
    }
    if (!formData.startDate) {
      toast.error(`Enter start date`);
      return false;
    }
    if (!formData.endDate) {
      toast.error(`Enter end date`);
      return false;
    }
    if (!formData.startTime) {
      toast.error(`Enter start time`);
      return false;
    }
    if (!formData.endTime) {
      toast.error(`Enter end time`);
      return false;
    }
    setSchedulingLoading(true);
    let sd = formData.startDate;
    let ed = formData.endDate;

    let sd_t = formData.startTime;
    let ed_t = formData.endTime;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);
    let fd = new FormData();

    if (formData.id) fd.append("id", formData.id);

    fd.append("title", formData.title);
    fd.append("meeting_start_time", getCurrentDateTimeInFormat(startDateTime));
    fd.append("meeting_end_time", getCurrentDateTimeInFormat(endDateTime));
    const scheduleMeetingRes = await fetchConnectAPI(fd, "schedule-meeting");
    if (scheduleMeetingRes.status == 200) {
      if (scheduleMeetingRes.response.status) {
        if (!formData.id) {
          setMeetingList((prev) => {
            return [scheduleMeetingRes?.response?.data?.updated_meetings[0], ...prev];
          });
        } else {
          let tempMeetingListAfterUpdate = meetingList.map((tempMeetingItem) => {
            if (tempMeetingItem.id == formData.id) {
              return scheduleMeetingRes?.response?.data?.updated_meetings[0];
            } else {
              return tempMeetingItem;
            }
          });
          setMeetingList(tempMeetingListAfterUpdate);
        }
        toast.success(scheduleMeetingRes.response.message);
        scheduleToggle();
        setFormData({
          title: "",
          room_name: null,
          id: null,
          startDate: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          endDate: new Date(),
        });
      } else {
        toast.error(scheduleMeetingRes.response.message);
      }
    }

    setSchedulingLoading(false);
  };
  const goLiveClick = async () => {
    setStartMeeting(true);
    let fd = new FormData();
    let createMeetingResponse = await fetchConnectAPI(fd, "create-meeting-code");
    if (createMeetingResponse.status === 200) {
      if (createMeetingResponse?.response?.status) {
        let createMeetingResponseData = createMeetingResponse?.response?.data;
        let localMeetingData = {
          room_name: createMeetingResponseData.room_name,
          is_admin: createMeetingResponseData.is_admin,
        };
        let localSettingData = {
          mic: createMeetingResponseData.setting.mic,
          camera: createMeetingResponseData.setting.camera,
        };
        localStorage.setItem("connect_setting", JSON.stringify(localSettingData));
        localStorage.setItem(createMeetingResponseData.room_name, JSON.stringify(localMeetingData));
        navigate(`/connect/${createMeetingResponseData.room_name}`);
      } else {
        toast.error(createMeetingResponse?.response?.message);
      }
    } else {
      toast.info("Please try again");
    }
    setStartMeeting(false);
  };
  const joinMeeting = () => {
    if (link.length != 9) {
      toast.error("Enter 9 character meeting code");
      return false;
    }
    navigate(`/connect/${link}`);
  };
  const settingToggle = () => {
    setSettingPopup(!settingPopup);
  };
  const linkKeyUpHandle = (e) => {
    if (e.keyCode === 13 && link.length == 9) {
      navigate(`/connect/${link}`);
    }
  };
  const currentDateTime = new Date();
  const [isJoinOpen, setIsJoinOpen] = useState(false);

  const joinToggle = () => {
    setIsJoinOpen(!isJoinOpen);
  };
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);

  const scheduleToggle = () => {
    setIsSchedulerOpen(!isSchedulerOpen);
    if (!formData.id) {
      let currentStartTime = new Date();
      let currentEndTime = new Date();
      currentStartTime.setMinutes(currentStartTime.getMinutes() + 5);
      currentEndTime.setMinutes(currentEndTime.getMinutes() + 20);
      setFormData({ ...formData, startTime: currentStartTime, endTime: currentEndTime });
    }
  };
  const deleteMeeting = async (meetingItem) => {
    setDeleteMeetingLoading(true);
    let fd = new FormData();
    fd.append("id", meetingItem.id);
    let deleteMeetingRes = await fetchConnectAPI(fd, "delete-schedule-meeting");
    if (deleteMeetingRes.status == 200) {
      if (deleteMeetingRes.response.status) {
        toast.success(deleteMeetingRes.response.message);
        let tempMeetingListAfterDelete = meetingList.filter((tempMeetingItem) => tempMeetingItem.id != meetingItem.id);
        setMeetingList(tempMeetingListAfterDelete);
      } else {
        toast.error(deleteMeetingRes.response.message);
      }
    } else {
      console.error("error in deleteMeeting");
    }
    setDeleteMeetingLoading(false);
  };
  const getScheduledMeetingList = async () => {
    setSchedulingListLoading(true);
    let fd = new FormData();
    fd.append("duration", "current");
    fd.append("duration_type", "days");
    let scheduleMeetingListRes = await fetchConnectAPI(fd, "schedule-meeting-list");
    if (scheduleMeetingListRes.status == 200) {
      if (scheduleMeetingListRes.response.status) {
        setMeetingList(scheduleMeetingListRes?.response?.data?.meetings);
      }
      setAudioOn(() => (scheduleMeetingListRes?.response?.data?.settings?.mic == "2" ? false : true));
      setVideoOn(() => (scheduleMeetingListRes?.response?.data?.settings?.camera == "2" ? false : true));
    } else {
      console.error("error in getScheduledMeetingList");
    }
    setSchedulingListLoading(false);
  };
  const selectMeeting = (meeting) => {
    if (meeting?.host) setSelectedMeeting(meeting);
  };
  const inviteMeeting = (meetingItem) => {
    setSelectedMeeting(meetingItem);
    setInvitePopup(true);
    setInviteMeetingLoading(false);
  };
  const editMeeting = (meetingItem) => {
    setEditMeetingLoading(true);

    setTimeout(() => {
      setIsSchedulerOpen(true);
      setEditMeetingLoading(false);
    }, 1000);
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  if (startMeeting) return <ConnectShimmer />;
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block className="h-center d-flex align-items-center">
          <div className="container-xl">
            <Row className="justify-content-between w-100 connectDashboard">
              <Col sm={12} lg={5} className="">
                <div>
                  <h3>Video Conferencing Made Easy with Connect</h3>
                  <p>
                    Connect with your team, friends, and family in real-time <br /> video meetings, from anywhere.
                  </p>
                  <div className="form-control-wrap d-flex">
                    <div className="input-group input-group-lg w-60">
                      <input
                        type="text"
                        className="form-control enterCodeLink"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="Enter a code or link"
                      />
                    </div>
                    <div className="input-group-prepend ms-lg-3">
                      <span
                        className={`input-group-btn px-4 join-button ${isFocused ? "fillBtn" : ""}`}
                        id="joinBtn"
                        onClick={joinMeeting}
                      >
                        Join
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-3 mt-lg-5">
                    <div className="videoTabs pointer" onClick={goLiveClick}>
                      <Icon name="video" />
                    </div>
                    <div
                      className="videoTabs pointer"
                      onClick={() => {
                        setFormData({
                          title: "",
                          room_name: null,
                          id: null,
                          startDate: new Date(),
                          startTime: new Date(),
                          endTime: new Date(),
                          endDate: new Date(),
                        });
                        scheduleToggle();
                      }}
                    >
                      <Icon name="calendar" />
                    </div>
                    <div className="videoTabs pointer" onClick={() => settingToggle()}>
                      <Icon name="setting" />
                    </div>
                    <div className="videoTabs pointer">
                      <Icon name="chat" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} lg={5} className="">
                <div className="text-center">
                  <div className="">
                    <img src="/connect.png" alt="connect.png" className="connect-img" />
                  </div>
                  <h4>Quick and Easy Meetings</h4>
                  <p>
                    Easily join meetings from your Silocalendar or Silomail, making it <br /> a hassle-free experience.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Block>
      </Content>
      <Modal isOpen={isJoinOpen} toggle={joinToggle}>
        <ModalHeader
          toggle={joinToggle}
          close={
            <button className="close" onClick={joinToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Join Meeting
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-evenly g-gs-2">
            <input
              className="form-control form-control-sm mx-2 w-50"
              placeholder="Enter a 9 character meeting code"
              value={link}
              onKeyUp={(e) => linkKeyUpHandle(e)}
              onChange={(e) => setLink(e.target.value)}
            />
            <Button color="secondary" disabled={link.length != 9} onClick={joinMeeting}>
              Join
            </Button>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light d-none">
          <span className="sub-text">Modal Footer Text</span>
        </ModalFooter>
      </Modal>
      <SettingModal
        settingPopup={settingPopup}
        settingToggle={settingToggle}
        setSettingPopup={setSettingPopup}
        audioOn={audioOn}
        videoOn={videoOn}
        setAudioOn={setAudioOn}
        setVideoOn={setVideoOn}
      />
      {selectedMeeting && selectedMeeting.host && (
        <InviteModal
          room_name={selectedMeeting.room_name}
          setInvitePopup={setInvitePopup}
          invitePopup={invitePopup}
          selectedMeeting={selectedMeeting}
          setMeetingList={setMeetingList}
          meetingList={meetingList}
          invitedMeetingId={invitedMeetingId}
          setInvitedMeetingId={setInvitedMeetingId}
          setSelectedMeeting={setSelectedMeeting}
        />
      )}
      <Modal isOpen={isSchedulerOpen} toggle={scheduleToggle}>
        <ModalHeader
          toggle={scheduleToggle}
          close={
            <button className="close" onClick={scheduleToggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Schedular {formData.room_name && `Room Name ${formData.room_name}`}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col sm="12">
              <div className="form-group">
                <Label htmlFor="title" className="form-label">
                  Title
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="title"
                    placeholder="Title"
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    value={formData.title}
                  />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Start Date &amp; Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData.startDate}
                        onChange={(date) => setFormData({ ...formData, startDate: date })}
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap has-timepicker">
                      <DatePicker
                        selected={formData.startTime}
                        onChange={(date) => setFormData({ ...formData, startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">End Date &amp; Time</label>
                <Row className="gx-2">
                  <div className="w-55">
                    <div className="form-control-wrap">
                      <DatePicker
                        selected={formData.endDate}
                        onChange={(date) => setFormData({ ...formData, endDate: date })}
                        className="form-control date-picker"
                        minDate={formData.startDate}
                      />
                    </div>
                  </div>
                  <div className="w-45">
                    <div className="form-control-wrap has-timepicker">
                      <DatePicker
                        selected={formData.endTime}
                        onChange={(date) => setFormData({ ...formData, endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col lg={12} sm={12} className="d-flex justify-content-end">
              {schedulingLoading ? (
                <>
                  <Button disabled color="primary">
                    <Spinner size="sm" />
                    <span>{formData.id ? `Updating...` : `Scheduling`} </span>
                  </Button>
                </>
              ) : (
                <Button color="primary" onClick={scheduleMeetingClick}>
                  {formData.id ? `Update` : `Schedule`}
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ConnectDashboard2;
