import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import Footer from "./footer/Footer";
import { getCookie } from "../utils/Utils";
import { io } from "socket.io-client";
export const socket = io("https://live.silocloud.io");
const ConnectLayout = ({ title, app, ...props }) => {
  const [authChecking, setAuthChecking] = useState(true);
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
    }
    const username = getCookie("username");
    const moduleName = process.env.REACT_APP_MODULE_NAME;
    socket.on(`notification_organizer_joined`, async (data) => {
      const notification_joined_data = await JSON.parse(data.data);
      if (notification_joined_data?.usernames.includes(username)) {
        const notification_joined_room_name = notification_joined_data?.room_name;
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            const nf = new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://api.silocloud.io/logo/apps/connect.png",
            });
            nf.onclick = () => {
              window.open(`https://connect.silocloud.io/connect/${notification_joined_room_name}`, "_blank");
            };
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                const nf = new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://api.silocloud.io/logo/apps/connect.png",
                });
                nf.onclick = () => {
                  window.open(`https://connect.silocloud.io/connect/${notification_joined_room_name}`, "_blank");
                };
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    socket.on(`notification_${moduleName}_${username}`, (data) => {
      if (data) {
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://api.silocloud.io/logo/apps/connect.png",
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://api.silocloud.io/logo/apps/connect.png",
                });
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
      socket.off(`notification_organizer_joined`);
    };
  }, []);
  return (
    <>
      <ToastContainer />
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppMain>
          <AppWrap nkWrap={false} styles={{ paddingTop: "65px" }}>
            <Header fixed publicMeeting={false} />
            <Outlet />
            <Footer fixed />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default ConnectLayout;
