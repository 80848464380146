import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";
import { toast } from "react-toastify";
import { fetchConnectAPI } from "../../utils/Utils";
import { useNavigate } from "react-router";

const GoLive = () => {
  const navigate = useNavigate();
  useEffect(() => {
    goLive();
  }, []);
  const goLive = async () => {
    toast.info("Creating meeting...");
    // setStartMeeting(true);
    let fd = new FormData();
    let createMeetingResponse = await fetchConnectAPI(fd, "create-meeting-code");
    if (createMeetingResponse.status === 200) {
      if (createMeetingResponse?.response?.status) {
        let createMeetingResponseData = createMeetingResponse?.response?.data;
        let localMeetingData = {
          room_name: createMeetingResponseData.room_name,
          is_admin: createMeetingResponseData.is_admin,
        };
        let localSettingData = {
          mic: createMeetingResponseData.setting.mic,
          camera: createMeetingResponseData.setting.camera,
        };
        localStorage.setItem("connect_setting", JSON.stringify(localSettingData));
        localStorage.setItem(createMeetingResponseData.room_name, JSON.stringify(localMeetingData));
        navigate(`/connect/${createMeetingResponseData.room_name}`);
      } else {
        toast.error(createMeetingResponse?.response?.message);
      }
    } else {
      toast.info("Please try again");
    }
    // setStartMeeting(false);
  };
  return (
    <React.Fragment>
      <Head title="Go Live" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <div className="position-relative ">
            <ShimmerThumbnail height={500} rounded />
            <ShimmerCircularImage size={150} className="circleImage" />
            <div className="d-flex video-icons">
              <img src="/voice.png" alt="" />
              <img src="/video.png" alt="" />
              <img src="/hang-up.png" alt="" />
            </div>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default GoLive;
