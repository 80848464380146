import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Tooltip } from "reactstrap";
import { CodeBlock, Icon, RSelect } from "../components/Component";
import useUserList from "../components/hooks/UseUserList";
import { toast } from "react-toastify";
import { fetchConnectAPI } from "../utils/Utils";
import TagsInput from "react-tagsinput";

const InviteModal = ({
  room_name,
  setInvitePopup,
  invitePopup,
  selectedMeeting,
  setMeetingList,
  meetingList,
  setSelectedMeeting,
  invitedMeetingId,
  setInvitedMeetingId,
}) => {
  const [visibility, setVisibility] = useState("0");
  const [inviteUserList, setInviteUserList] = useState([]);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [publicInfoTooltipOpen, setPublicInfoTooltipOpen] = useState(false);
  const [filterUserList, setFilterUserList] = useState([] || null);
  const [userInputValue, setUserInputValue] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const UserList = useUserList();
  useEffect(() => {
    setInviteUserList(selectedMeeting.invited_usernames_list);
    setInviteEmails(selectedMeeting.invited_emails_list);
    setVisibility(selectedMeeting.visibility);
  }, []);
  useEffect(() => {
    const List = UserList.filter((option) => option.label.toLowerCase().includes(userInputValue.toLowerCase()));
    setFilterUserList(List.slice(0, 5));
  }, [setFilterUserList, UserList, userInputValue]);
  const handleUserSearch = async (e) => {
    const inputValue = e.map((option) => option.value);
    setInviteUserList(inputValue);
  };
  const visibilityClicked = async (visibilityValue) => {
    // toast.info(`Updating visibility`);
    const fd = new FormData();
    fd.append("visibility", visibilityValue);
    let setMeetingVisibilityRes = await fetchConnectAPI(fd, `set-meeting-visibility/${room_name}`);
    if (setMeetingVisibilityRes.status == 200) {
      if (setMeetingVisibilityRes.response.status) {
        toast.success(setMeetingVisibilityRes.response.message);
      } else {
        toast.error(setMeetingVisibilityRes.response.message);
        setVisibility(selectedMeeting.visibility);
      }
    } else {
      console.log("error in setting visibility");
    }
  };
  const handleChangeTags = (tags) => {
    const regexEmail =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    if (allTagsValid) {
      setInviteEmails(tags);
    } else {
      toast.error("Enter valid email addresses");
    }
  };
  const inviteUsersClick = async () => {
    setInviteLoading(true);

    const data = {
      visibility: visibility,
      invited_usernames: inviteUserList,
      invited_emails: inviteEmails,
    };
    let inviteRes = await fetchConnectAPI(data, `invite-to-meeting/${room_name}`, true, "json");
    if (inviteRes.status == 200) {
      if (inviteRes.response.status) {
        setInvitedMeetingId(null);
        toast.success(inviteRes.response.message);
        let tmpMeetingList = meetingList.map((meetingItem) => {
          if (meetingItem.room_name == room_name) {
            return inviteRes?.response?.data?.updated_meetings[0];
          } else {
            return meetingItem;
          }
        });
        setMeetingList(tmpMeetingList);
        setInvitePopup(false);
      } else {
        toast.error(inviteRes.response.message);
      }
    } else {
      toast.error("Please try again");
    }
    setInviteLoading(false);
  };
  const publicInfoTooltipToggle = () => {
    setPublicInfoTooltipOpen(!publicInfoTooltipOpen);
  };
  const inviteToggle = () => {
    setInvitePopup(!invitePopup);
  };
  return (
    <Modal isOpen={invitePopup} toggle={inviteToggle}>
      <ModalHeader
        toggle={inviteToggle}
        close={
          <button
            className="close text-primary"
            onClick={() => {
              setSelectedMeeting(null);
              inviteToggle();
            }}
          >
            <Icon name="cross" />
          </button>
        }
      >
        Connect Invitation - {room_name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={12} lg={12} className={`mb-2`}>
            <span className="preview-title overline-title">Visibility</span>
            <div className="custom-control custom-control-sm custom-radio me-2">
              <input
                type="radio"
                className="custom-control-input"
                name="radioVisibility"
                checked={visibility === "0"}
                onChange={() => console.log("")}
                id="rdoNone"
                onClick={() => {
                  visibilityClicked(0);
                  setVisibility("0");
                }}
              />
              <label className="custom-control-label" htmlFor="rdoNone">
                None
              </label>
            </div>
            <div className="custom-control custom-control-sm custom-radio me-2">
              <input
                type="radio"
                className="custom-control-input"
                name="radioVisibility"
                checked={visibility === "1"}
                onChange={() => console.log("")}
                id="rdoFollowers"
                onClick={() => {
                  visibilityClicked(1);
                  setVisibility("1");
                }}
              />
              <label className="custom-control-label" htmlFor="rdoFollowers">
                Followers
              </label>
            </div>
            <div className="custom-control custom-control-sm custom-radio me-2">
              <input
                type="radio"
                className="custom-control-input"
                name="radioVisibility"
                id="rdoConnection"
                onClick={() => {
                  visibilityClicked(2);
                  setVisibility("2");
                }}
                onChange={() => console.log("")}
                checked={visibility === "2"}
              />
              <label className="custom-control-label" htmlFor="rdoConnection">
                Connection
              </label>
            </div>
            <div className="custom-control custom-control-sm custom-radio me-2">
              <input
                type="radio"
                className="custom-control-input"
                name="radioVisibility"
                id="rdoPublic"
                onChange={() => console.log("")}
                onClick={() => {
                  visibilityClicked(3);
                  setVisibility("3");
                }}
                checked={visibility === "3"}
              />
              <label className="custom-control-label" htmlFor="rdoPublic">
                Public using share link
                <Icon className="px-1 mt-1 d-none" name={`info`} id={"public_info"} />
                <Tooltip
                  placement={`right`}
                  isOpen={publicInfoTooltipOpen}
                  target="public_info"
                  toggle={publicInfoTooltipToggle}
                >
                  Click invite to set public
                </Tooltip>
              </label>
            </div>
          </Col>
          {visibility == "3" && (
            <Col lg={12} sm={12} className="my-1">
              <CodeBlock language="jsx" title="Share Link">
                {`${window.location.origin}/public/${room_name}`}
              </CodeBlock>
            </Col>
          )}
          <Col sm={12} lg={12} className={`mb-2`}>
            <div className="form-group">
              <label className="form-label">Invite Users</label>
              <div className="form-control-wrap ">
                <RSelect
                  placeholder="Search User..."
                  options={filterUserList}
                  isMulti
                  defaultValue={
                    Array.isArray(selectedMeeting.invited_usernames_list)
                      ? selectedMeeting.invited_usernames_list.map((username) => ({ value: username, label: username }))
                      : []
                  }
                  onChange={handleUserSearch}
                  onInputChange={(value) => {
                    setUserInputValue(value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} lg={12} className={`mb-2`}>
            <div className="form-group">
              <label className="form-label">Invite Emails</label>
              <div className="form-control-wrap ">
                <TagsInput
                  className="form-control p-0 w-100"
                  value={Array.isArray(inviteEmails) ? inviteEmails : [inviteEmails]}
                  onChange={handleChangeTags}
                  inputProps={{
                    className: "w-100 react-tagsinput-input tagsinput-color",
                    placeholder: "Enter E-mail",
                  }}
                />
              </div>
            </div>
          </Col>

          <Col sm={12} lg={12} className={`mb-2 d-flex justify-content-end`}>
            {inviteLoading ? (
              <>
                <Button disabled color="primary">
                  <Spinner size="sm" />
                  <span> Inviting... </span>
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={inviteUsersClick}>
                Invite
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InviteModal;
