import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Tooltip,
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  RSelect,
  CodeBlock,
} from "../components/Component";
import { fetchConnectAPI, getCookie } from "../utils/Utils";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import ConnectShimmer from "./connect/ConnectShimmer";
import { toast } from "react-toastify";
import useUserList from "../components/hooks/UseUserList";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Pusher from "pusher-js";
import { socket } from "../layout/ConnectLayout";

const PublicConnect = () => {
  const navigate = useNavigate();

  const { room_name } = useParams();
  const [verifyMeeting, setVerifyMeeting] = useState(true);
  let localMeetingData = localStorage.getItem(room_name);
  useEffect(() => {
    verifyMeetingAccess();
    socket.on(`notification_left_${room_name}`, (data) => {
      toast.info("Organizer left meeting");
      setVerifyMeeting(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    });
    return () => {
      socket.off(`notification_left_${room_name}`);
    };
  }, []);
  const verifyMeetingAccess = async () => {
    let verifyMeetingResponse = await fetchConnectAPI(null, `public/is-public-meeting/${room_name}`, false);
    if (verifyMeetingResponse.status === 200) {
      if (verifyMeetingResponse?.response?.status) {
        setVerifyMeeting(false);
      } else {
        toast.error(verifyMeetingResponse?.response?.message);
        setTimeout(() => {
          navigate(`/`);
          setVerifyMeeting(false);
        }, 2000);
      }
    } else {
      toast.info("Please try again");
      setVerifyMeeting(false);
    }
  };

  if (verifyMeeting) return <ConnectShimmer />;
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3" className="d-none">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none ">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            <ConnectJitsi lg={12} room_name={room_name} setVerifyMeeting={setVerifyMeeting} />
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
const ConnectJitsi = ({ room_name, setVerifyMeeting, lg = 12, isAdmin = false, localMeetingData }) => {
  const navigate = useNavigate();
  const jitsiContainerStyle = {
    width: "100%",
    height: "100%",
    color: "#000",
    backgroundColor: "#000",
  };
  const onApiReady = (externalApi) => {
    externalApi.addEventListener("videoConferenceLeft", () => {
      if (localMeetingData) {
        localStorage.removeItem(room_name);
      }
      setVerifyMeeting(true);
      if (isAdmin) closeMeeting();
      toast.info("You have left meeting");
      setTimeout(() => {
        navigate(`/`);
      }, 2000);
    });
    externalApi.addEventListener("participantLeft", (user) => {
      const fd = new FormData();
      fd.append("admin_id", user.id);
      fd.append("is_admin_joined", "0");
      // const adminLeftRes = fetchConnectAPI(fd, `public/leave-meeting/${room_name}`, false);
    });
  };
  const closeMeeting = async () => {
    let verifyMeetingResponse = await fetchConnectAPI(null, `close-meeting/${room_name}`);
    if (verifyMeetingResponse.status === 200) {
      if (verifyMeetingResponse?.response?.status) {
        toast.success(verifyMeetingResponse?.response?.message);
        setVerifyMeeting(true);
        setTimeout(function () {
          navigate("/");
        }, 2000);
      } else {
        toast.error(verifyMeetingResponse?.response?.message);
      }
    } else {
      toast.error("Please try again");
    }
  };
  const configOverwrite = {
    prejoinPageEnabled: false,
    remoteVideoMenu: { disableKick: false },
    disableRemoteMute: false,
    disableProfile: true,
    disableInviteFunctions: true,
    enableWelcomePage: false,
    enableClosePage: true,
    deeplinking: { disabled: true },
  };
  const adminToolbar = isAdmin ? ["mute-everyone", "mute-video-everyone"] : [];
  const interfaceConfigOverwrite = {
    CLOSE_PAGE_GUEST_HINT: true,
    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    HIDE_INVITE_MORE_HEADER: true,
    SETTINGS_SECTIONS: isAdmin ? ["devices", "language", "moderator", "profile"] : ["devices", "language", "profile"],
    SHARING_FEATURES: [],
    TOOLBAR_BUTTONS: [
      "microphone",
      "camera",
      "closedcaptions",
      "desktop",
      // "fullscreen",
      "fodeviceselection",
      "profile",
      "chat",
      "etherpad",
      "sharedvideo",
      "settings",
      "raisehand",
      "videoquality",
      "filmstrip",
      "stats",
      "shortcuts",
      "tileview",
      "select-background",
      "download",
      "security",
      ...adminToolbar,
    ],
  };
  const userInfo = {
    email: getCookie("email", "guest@mail.com"),
    displayName: getCookie("username", `${process.env.REACT_APP_PROJECT_SHORT_NAME} Guest`),
  };
  return (
    <>
      <Col sm={12} lg={lg} className="text-center">
        <div id="jitsi-container" style={jitsiContainerStyle}>
          <JitsiMeeting
            domain={`${process.env.REACT_APP_JITSI_DOMAIN}`}
            roomName={room_name}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = `${isAdmin ? "500px" : "600px"}`;
            }}
            onApiReady={onApiReady}
            // configOverwrite={configOverwrite}
            // interfaceConfigOverwrite={interfaceConfigOverwrite}
            userInfo={userInfo}
          />
        </div>
      </Col>
    </>
  );
};
export default PublicConnect;
